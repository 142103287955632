
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import isEnabled from "@/composables/isEnabled.js";

import { useMsal } from "@/composition-api/useMsal";

export default defineComponent({
  name: "Manutenzione",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
    const { instance } = useMsal();
    const signOut = async () => {
      const idTokenHint = localStorage.getItem("tokenAdb2c") as string;
      localStorage.clear();
      await instance.logoutRedirect({
        idTokenHint: idTokenHint,
      });
    };

    const pulisciTutteCose = () => {
      localStorage.clear();
      sessionStorage.clear();
      function deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      deleteAllCookies();
    };

    return {
      isEnabled,
      signOut,
      pulisciTutteCose,
    };
  },
});
